import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import Emoji from '../emoji/emoji';

const Container = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.span`
  margin: 0 ${rem(10)};
  color: ${(props) => props.color || 'inherit'};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin: ${rem(20)} 0;
`;

const EmojiContainer = styled.span`
  &:hover + span {
    color: inherit;
  }
`;

const NotFound: FC = () => (
  <Container>
    <Row>
      <Emoji emoji={'🤷‍♂️'} label={'Man shrugging'} />
      <Text>Page pas trouvée</Text>
    </Row>
    <Row>
      <EmojiContainer>
        <Emoji emoji={'🔦'} label={'Flashlight'} />
      </EmojiContainer>
      <Text color={colors.text.slight}>(Page not found)</Text>
    </Row>
  </Container>
);

export default NotFound;
