import React from 'react';

import AppContainer from '../components/app-container/app-container';
import NotFound from '../components/not-found/not-found';

export default () => (
  <AppContainer>
    <NotFound />
  </AppContainer>
);
